.sidebar {
    background: #f2f2f2;
}

.sidebar__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding: 16px 12px;

    @include tablet {
        max-width: 300px;
    }

    .sponsor {
        position: relative;
        display: flex;
        padding: 50% 0 0;
        width: 100%;

        &:hover {
            img {
                filter: saturate(1);
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 60%;
            max-width: 80%;
            filter: saturate(0.2);
            transition: all 0.2s;
        }
    }

    .sponsor--hoofdsponsor,
    .sponsorclicks {
        grid-column-start: 1;
        grid-column-end: 3;
        max-width: 100%;
    }

    .sponsor--hoofdsponsor {
        @include mobile-only {
            grid-column-start: auto;
            grid-column-end: auto;
        }
    }
}

.sponsorclicks {
    padding: 0;

    iframe {
        width: 100%;
        max-width: 100%;
    }
}