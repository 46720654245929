// INLCUDE FONTS
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Candal&display=swap');

h1 {
    font-family: 'Candal', sans-serif;
}

p {
    font-size: 16px;
}