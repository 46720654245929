




























































































































#program.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#program.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#program.loading > * {
  display: none;
}

.table {
  border-collapse: collapse;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.table th,
.table td {
  padding: 10px 10px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  border-top: 1px solid #eee;
  border-left: 1px solid #f2f2f2;
}

.table tbody {
  border-top: none;
}

.table tbody:nth-child(even) td {
  background: rgba(0, 0, 0, 0.03);
}

.table tr {
  cursor: pointer;
}

.table tr:hover td {
  background: #f2f2f2;
}

.table .active td {
  background: #f2f2f2;
}

.table__info td {
  background: #f2f2f2;
  padding-bottom: 20px;
}

td.location {
  text-transform: capitalize;
}

.show-more-button {
  -webkit-appearance: none;
  width: 100%;
  height: 42px;
  font-size: 14px;
  background: #f2f2f2;
  border: none;
  margin: 8px 0 0;
  border-radius: 5px;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.bold {
  font-weight: 900;
}

.section .table--program {
  text-align: left;
}
