.pagination {
    display: flex;

    .link {
        margin: 0 4px 0 0;
        padding: 4px;
        min-width: 24px;
        border-radius: $border-radius;
        background: $background-light;
        color: $font-primary;
        text-align: center;
        text-decoration: none;

        &.link--disabled{
            opacity: 0.4;
        }
    }
    .link--active{
        background: $primary;
        color: #fff;
    }
}