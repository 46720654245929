























































































































































































































#poule.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#poule.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#poule.loading > * {
  display: none;
}

.align-left {
  text-align: left;
}

.poule-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select {
  position: relative;
  margin-left: 8px;
}

.select:after {
  content: "";
  display: block;
  position: absolute;
  right: 8px;
  top: calc(50% - 4px);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #666;
}

.poule-header .section-title {
  margin: 0 auto 8px 0;
}

.poule-buttons {
  display: flex;
  padding: 0 0 8px;
  margin: 0 0 16px;
  border-bottom: 1px solid #eee;
}

.poule-button,
.poule-select {
  display: flex;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 8px 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #666;

  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.poule-button:hover {
  background: #f2f2f2;
}

.poule-button.active {
  background: rgba(0, 153, 15, 0.7);
  color: #fff;
}

.poule-select {
  background: #f2f2f2;
  padding: 8px 32px 8px 12px;
}

.team-pos {
  display: flex;
  justify-content: center;
  max-width: 30px;
  padding: 5px;
  border-radius: 3px;
  background: #f2f2f2;
  color: #666;
}

.poule-table {
  overflow: scroll;
}

.poule-table .current td {
  background: rgba(0, 153, 15, 0.1);
  border: none;
  border-bottom: 1px solid #fff;
}

.poule-table .current td .team-pos {
  background: rgba(0, 153, 15, 0.7);
  color: #fff;
}

.poule-table table {
  width: 100%;
  text-align: center;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-collapse: collapse;
}

.poule-table th {
  padding: 5px 8px;
  background: #f2f2f2;
  color: #666;
  font-size: 12px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.poule-table td {
  padding: 5px 8px;
  border-bottom: 1px solid #f2f2f2;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.poule-standings td:first-child,
.poule-standings th:first-child {
  max-width: 25px;
  padding: 5px 0 5px 8px;
}

@media (max-width: 768px) {
  .poule-header {
    flex-direction: column;
  }

  .poule-header > *,
  .poule-header select {
    width: 100%;
  }

  .select {
    margin: 0;
  }

  .poule-header > * {
    margin-bottom: 8px;
  }
}
