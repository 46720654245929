// POSSIBLE COLLORS
$blue: blue;
$green: green;
$red: red;
$black: #000;
$gray: #ccc;

$primary: rgb(0, 153, 15);
$font-primary: #333;
$background-light: #f2f2f2;

// COLOR HIERARCHY
$color-first: $blue;
$color-second: darken($blue, 10%);
$color-third: lighten($blue, 10%);
$color-fourth: rgba($blue, 0.7);

$color-fifth: $blue;
$color-sixth: $blue;
$color-seventh: $blue;
$color-eighth: $blue;

$color-seventh: $blue;
$color-tenth: $blue;