@keyframes shake-right {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(5px);
  }
}

@keyframes shake-left {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
