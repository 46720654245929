































































.mobile-menu-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 5;
	top: 7px;
	right: 7px;
	height: 42px;
	width: 42px;
	color: #fff;
	background: none;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	border: none;

	svg {
		height: 22px;
	}

	&.open {
		color: #000;
	}
}
