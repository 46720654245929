.section--news {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
}

.news-item {
  display: flex;
  margin: 0 0 16px;
  color: #000;
  text-decoration: none;

  h3 {
    font-size: 20px;
  }

  .intro {
    margin: 4px 0 8px;
    opacity: 0.9;
  }

  .date {
    margin: 0 0 4px;
    padding: 0 0 0 8px;
    font-size: 12px;
    color: $primary;
    border-left: 1px solid #00990f;
  }

  .read-more {
    color: $primary;
    font-size: 14px;
  }
}

.news-item__image {
  position: relative;
  width: 100%;
  max-width: 150px;
  background: #999;
  border-radius: 5px;

  @include mobile-only {
    align-self: self-start;
    flex: 0 0 75px;
    height: 75px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .placeholder {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 90%;
    object-fit: contain;
  }
}

.news-item__content {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}