// DIVICE SIZES
$tablet: "768px";
$desktop: "992px";
$desktop-big: "1800px";

// BASIC QUERIES
@mixin mobile-only {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}

@mixin desktop-big {
  @media only screen and (min-width: #{$desktop-big}) {
    @content;
  }
}