.header {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    min-height: 33vh;
    padding: 32px 0 0;
    color: #fff;
    background-color: $primary;
    background-size: cover;
    background-position: center;

    .container {
        position: relative;
        z-index: 3;

        @include tablet {}

        @include desktop {
            margin: 0 0 0 calc(10vw + 16px);
        }

        @include desktop-big {}
    }

    h1 {
        margin: 0 0 -13px;
        text-transform: uppercase;
        font-size: 60px;

        @include mobile-only {
            margin: 0 0 -7px;
            font-size: 32px;
        }
    }

    img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100vw;
        max-width: 100vw;
        object-fit: cover;
        object-position: center;
        filter: grayscale(1);
        opacity: 0.7;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(0, 153, 15, 0.5), rgba(31, 107, 0, 0.2));
    }

    &:after {
        background-image: radial-gradient(rgba(31, 107, 0, 1) 20%, transparent 20%),
            radial-gradient(rgba(31, 107, 0, 1) 20%, transparent 20%);
        background-position: 0 0, 4px 4px;
        background-size: 8px 8px;
        opacity: 0.3;
    }

    &.has-backgroundimage {

        .wave1,
        .wave2,
        .wave3 {
            display: none;
        }
    }

    .wave1,
    .wave2,
    .wave3 {
        position: absolute;
        z-index: 3;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: 0.05;
    }
}