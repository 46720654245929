// INCLUDE GLOBAL MIXINS
@import "./utils/utils.scss";

// INCLUDE GLOBAL STYLING
@import "./global/globals.scss";

// INCLUDE COMPONENTS STYLING
@import "./components/components.scss";

// INLCUDE STYLING FROM CRAFT TEMPLATES
@import "../../templates/styles/home.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html.no-scroll,
html.no-scroll body {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

body {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
}