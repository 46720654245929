.footer {
    display: flex;
    padding: 20px 40px;
    color: #fff;
    background: #333;

    @include mobile-only {
        flex-direction: column;
    }

    .container {
        display: flex;
    }

    .logo {
        margin: 0 auto 0 0;

        img {
            height: 42px;
        }
    }

    a {
        text-decoration: none;
    }

    .footer-nav {
        padding: 12px 0 0;
    }

    ul {
        display: flex;
        list-style-type: none;

        @include mobile-only {
            flex-direction: column;
        }

        a {
            display: block;
            margin: 0 0 12px;
        }

        li {
            @include tablet {
                margin: 0 0 0 40px;
            }
        }

        ul {
            flex-direction: column;
            opacity: 0.5;

            li {
                margin-left: 0;
            }
        }
    }

    .teams-overview {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 200px;

        li {
            width: 100%;
            max-width: 50%;
        }
    }

    a {
        color: #fff;
    }
}