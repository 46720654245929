.team {
    .row {
        display: flex;
    }

    .column {
        flex: 1;
    }

    .table {
        th {
            padding: 4px 0;
            text-align: left;
        }

        td {
            padding: 4px 0;
            border-top: 1px solid #f2f2f2;
        }
    }
}