.content {
    display: flex;
    flex-direction: column;

    * {
        max-width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 8px;
        max-width: 600px;
    }

    .news-title {
        margin: 0 0 32px;
        font-size: 32px;
    }

    .article-date {
        margin: 0 0 8px;
        padding: 0 0 0 8px;
        color: #999;
        font-size: 14px;
        border-left: 1px solid #aaa;
    }

    p,
    ul,
    ol,
    blockquote {
        margin: 0 0 22px;
        line-height: 144%;
        color: $font-primary;
        max-width: 600px;
    }

    a {
        color: $primary;
    }

    ul,
    ol {
        margin-left: 28px;

        li {
            margin-bottom: 4px;
        }
    }

    blockquote {
        margin: 22px 0 44px;
        padding: 20px;
        background: #f2f2f2;
        border-radius: 3px;
        font-size: 18px;
        color: #666;
    }

    figure {
        margin: 0 0 22px;
        position: relative;

        figcaption {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 6px 8px 6px;
            color: #999;
            font-size: 11px;
            border-radius: 0 3px 0 0;
            background: rgba(255, 255, 255, 0.1);
        }
    }

    #program {
        position: relative;
        overflow-x: scroll;
    }
}