








































































































































#results.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#results.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#results.loading > * {
  display: none;
}

.result-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 782px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.result-list h2,
.result-list p {
  margin: 0;
}

.result-list .result {
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.result-list .result:hover {
  background: rgba(0, 0, 0, 0.02);
}

.result-list .result:hover .points,
.result-list .result--info.active .points {
  background: rgba(0, 152, 14, 1);
  color: #fff;
}

.result-list .result--date {
  padding: 5px 8px;
  background: #eee;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.result-list .result--info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 0;
  width: 100%;
}

.result-list .result--info.active {
  background: #eee;
}

.result-list .result--info * {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.result-list .result--info .team {
  flex: 2;
  font-weight: normal;
  font-size: 16px;
}

.result-list .result--info .points {
  flex: 1;
  padding: 5px 8px;
  background: #eee;
  font-size: 20px;
}

.result-list .result--info .time {
  flex: 1;
}

.result--info {
  display: flex;
}

.result__extra-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}
