.navigation {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    padding: 0 16px;

    height: 70px;
    width: 100%;
    // background: #244227;

    .mobile-menu-button {
        @include tablet {
            display: none;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    .logo {
        margin: 0 auto 0 0;

        img {
            height: 50px;

            @include tablet {
                height: 54px;
            }
        }
    }

    .main-nav {
        display: flex;
        height: inherit;
        list-style-type: none;
        padding: 10px 0;

        @include mobile-only {
            position: fixed;
            overflow-y: scroll;
            top: 0;
            right: -100vw;
            height: 100vh;
            width: 100vw;
            background: #fff;
            transition: all 0.2s;

            a {
                color: #000;
            }
        }

        &.open {
            display: flex;

            @include mobile-only {
                right: 0;
                flex-direction: column;
            }
        }

        li {
            position: relative;

            @include tablet {
                height: 100%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 20px;
                height: inherit;
                border-radius: 3px;
                opacity: 0.8;

                &.active {
                    opacity: 1;
                    font-weight: bold;
                }

                @include mobile-only {
                    padding: 12px 20px;
                    border-bottom: 1px solid #f2f2f2;
                }
            }

            &.has-dropdown {
                a {
                    border-radius: 3px 3px 0 0;
                }
            }

            &:hover {
                a {
                    opacity: 1;
                    background: #fff;
                    color: $font-primary;
                }

                ul {
                    display: flex;
                }
            }
        }

        ul {
            list-style-type: none;
            flex-direction: column;
            min-width: 150px;
            background: #fff;
            border-radius: 3px 0 3px 3px;

            @include mobile-only {
                padding-left: 20px;
            }

            @include tablet {
                display: none;
                position: absolute;
                overflow: hidden;
                top: 50px;
                right: 0;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
            }

            li {
                margin: 0;

                @include mobile-only {
                    margin-left: 20px;
                }

                &:hover a {
                    background: rgba(0, 0, 0, .1);
                }
            }

            a {
                padding: 12px;
                color: $font-primary;
                border-bottom: 1px solid #f2f2f2;
            }
        }
    }
}