// BTN BASE STYLING
.button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 0;
    padding: 0 12px;
    height: 42px;
    min-width: 250px;
    border-radius: 3px;
    text-decoration: none;
    background: #f2f2f2;
    color: #000;

    strong {
        margin: 0 8px 0 0;
    }

    &:hover {
        background: #eee;
    }
}