// BASE GRID LAYOUT STYLING

.page {
  display: flex;
  flex-direction: column;

  @include tablet {
    display: grid;
    grid-template-columns: 0 1fr 200px 0;
    grid-template-rows: 33vh 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }

  @include desktop {
    display: grid;
    grid-template-columns: 10vw 1fr 300px 0;
    grid-template-rows: 33vh 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }

  @include desktop-big {
    display: grid;
    grid-template-columns: 1fr 612px 300px 1fr;
    grid-template-rows: 33vh 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }
}

.header {
  @include tablet {
    grid-area: 1 / 1 / 2 / 5;
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding: 16px;

  .sponsorclicks {
    margin-top: auto;
    padding: 32px 0 0;

    @include mobile-only {
      display: none;
    }
  }

  @include tablet {
    grid-area: 2 / 2 / 3 / 3;
    padding: 32px 0;
  }
}

.sidebar {
  @include tablet {
    grid-area: 2 / 3 / 3 / 5;
  }
}

.container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 960px;
}

// DISTANCES, POSITIONS ETC.